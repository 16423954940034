import React, {
  Fragment,
  useState,
  useCallback,
  useRef,
  useEffect,
} from 'react';
import { isMobileSafari, isSafari } from 'react-device-detect';
import classNames from 'classnames';
import { useVideo } from 'react-use';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation,
} from 'react-router-dom';

import { useEventListener } from './hooks';
import './App.css';
import LogoExt_BlueYellow from './assets/CompanyKitchen_LogoExt_YellowBlue_800.png';

import LogoCompact_ColorCapital from './assets/CompanyKitchen_LogoCompact_Color_Capital_800.png';

import Video_Teaser from './assets/CompanyKitchen_Teaser_H264.mp4';

import Rotate_Phone from './assets/rotate_phone.png';
import Play_Button from './assets/play-button.png';

import ReactPlayer from 'react-player';
let padding = 47;
let widthAux = 83;

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App(props) {
  const videoRef = useRef(null);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
    oldHeigth: 0,
    padding: 140,
  });
  const [playerControls, setPlayerControls] = useState(true);
  // Event handler utilizing useCallback ...
  // ... so that reference never changes.
  const handler = useCallback(
    (event) => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        oldHeigth: windowSize.height - window.innerHeight,
        padding: windowSize.padding + windowSize.oldHeigth,
      });
    },
    [windowSize.height, windowSize.oldHeigth, windowSize.padding],
  );

  // Add event listener using our hook
  useEventListener('resize', handler);
  useEventListener('orientationchange', handler);

  let logo_ext = LogoExt_BlueYellow;
  let logo_compact = LogoCompact_ColorCapital;

  let width = windowSize.width;

  let height = width * 0.5625;

  let style = {};

  if (windowSize.height > 1560) {
  }
  height = '100%';
  width = '100%';

  const ratio = windowSize.width / windowSize.height;

  return (
    <Router>
      <ScrollToTop />
      <div className="main-wrapper">
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route path="/impressum">
            <div
              className={classNames('impressum', {
                active: true,
              })}
            >
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <Link to="/"> {'<-'} Back</Link>
                    <h1>Legal Disclosure</h1>
                    <p>Information in accordance with TMB</p>

                    <ul>
                      <li>Highline SL</li>
                      <li> c./ Olof Palme 43, 4C</li>
                      <li>35010 Las Palmas de Gran Canaria, Spain</li>
                      <li>
                        Represented by
                        <br />
                        Daniel Staarmann
                      </li>
                    </ul>

                    <h2>Contact Information</h2>
                    <ul>
                      <li>
                        <strong>Telephone:</strong> +49 177 448 3382
                      </li>
                      <li>
                        <strong>E-Mail:</strong> hungry@company.kitchen
                      </li>
                      <li>
                        <strong>Internet address:</strong> www.company.kitchen
                      </li>
                    </ul>

                    <h2>Register entry</h2>
                    <ul>
                      <li>
                        <strong>Register Number:</strong> B76114131
                      </li>
                    </ul>

                    <h2>Trademark Notices</h2>
                    <p>
                      <strong>company.kitchen®</strong> is a registered trade
                      mark of staarmann & partners GmbH in the European Union
                    </p>

                    <h2>Disclaimer</h2>
                    <h3>Accountability for content</h3>
                    <p>
                      The contents of our pages have been created with the
                      utmost care. However, we cannot guarantee the contents'
                      accuracy, completeness or topicality. According to
                      statutory provisions, we are furthermore responsible for
                      our own content on these web pages. In this matter, please
                      note that we are not obliged to monitor the transmitted or
                      saved information of third parties, or investigate
                      circumstances pointing to illegal activity. Our
                      obligations to remove or block the use of information
                      under generally applicable laws remain unaffected by this
                      as per §§ 8 to 10 of the Telemedia Act (TMG).
                    </p>

                    <h3>Accountability for links</h3>
                    <p>
                      Responsibility for the content of external links (to web
                      pages of third parties) lies solely with the operators of
                      the linked pages. No violations were evident to us at the
                      time of linking. Should any legal infringement become
                      known to us, we will remove the respective link
                      immediately.
                    </p>

                    <h3>Copyright</h3>
                    <p>
                      Our web pages and their contents are subject to German
                      copyright law. Unless expressly permitted by law, every
                      form of utilizing, reproducing or processing works subject
                      to copyright protection on our web pages requires the
                      prior consent of the respective owner of the rights.
                      Individual reproductions of a work are only allowed for
                      private use. The materials from these pages are
                      copyrighted and any unauthorized use may violate copyright
                      laws
                    </p>
                    <Link to="/"> {'<-'} Back</Link>
                  </div>
                </div>
              </div>
            </div>
          </Route>
          <Route path="/privacy-policy">
            <div
              className={classNames('privacy-policy', {
                active: true,
              })}
            >
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <Link to="/"> {'<-'} Back</Link>
                    <h1>Welcome to our Privacy Policy</h1>
                    <p>-- Your privacy is critically important to us.</p>

                    <p>www.company.kitchen is located at:</p>
                    <p>
                      www.company.kitchen,
                      <br />
                      00491774483382
                    </p>

                    <p>
                      It is www.company.kitchen's policy to respect your privacy
                      regarding any information we may collect while operating
                      our website. This Privacy Policy applies to
                      www.company.kitchen (hereinafter, "us", "we", or
                      "www.company.kitchen"). We respect your privacy and are
                      committed to protecting personally identifiable
                      information you may provide us through the Website. We
                      have adopted this privacy policy ("Privacy Policy") to
                      explain what information may be collected on our Website,
                      how we use this information, and under what circumstances
                      we may disclose the information to third parties. This
                      Privacy Policy applies only to information we collect
                      through the Website and does not apply to our collection
                      of information from other sources. This Privacy Policy,
                      together with the Terms and conditions posted on our
                      Website, set forth the general rules and policies
                      governing your use of our Website. Depending on your
                      activities when visiting our Website, you may be required
                      to agree to additional terms and conditions.
                    </p>

                    <h2>Website Visitors</h2>
                    <p>
                      Like most website operators, www.company.kitchen collects
                      non-personally-identifying information of the sort that
                      web browsers and servers typically make available, such as
                      the browser type, language preference, referring site, and
                      the date and time of each visitor request.
                      www.company.kitchen's purpose in collecting non-personally
                      identifying information is to better understand how
                      www.company.kitchen's visitors use its website. From time
                      to time, www.company.kitchen may release
                      non-personally-identifying information in the aggregate,
                      e.g., by publishing a report on trends in the usage of its
                      website. www.company.kitchen also collects potentially
                      personally-identifying information like Internet Protocol
                      (IP) addresses for logged in users and for users leaving
                      comments on{' '}
                      <a href="www.company.kitchen">
                        http://www.company.kitchen
                      </a>{' '}
                      blog posts. www.company.kitchen only discloses logged in
                      user and commenter IP addresses under the same
                      circumstances that it uses and discloses
                      personally-identifying information as described below.
                    </p>

                    <h2>Gathering of Personally-Identifying Information</h2>
                    <p>
                      Certain visitors to www.company.kitchen's websites choose
                      to interact with www.company.kitchen in ways that require
                      www.company.kitchen to gather personally-identifying
                      information. The amount and type of information that
                      www.company.kitchen gathers depends on the nature of the
                      interaction. For example, we ask visitors who sign up for
                      a blog at{' '}
                      <a href="www.company.kitchen">
                        http://www.company.kitchen
                      </a>{' '}
                      to provide a username and email address.
                    </p>

                    <h2>Security</h2>
                    <p>
                      The security of your Personal Information is important to
                      us, but remember that no method of transmission over the
                      Internet, or method of electronic storage is 100% secure.
                      While we strive to use commercially acceptable means to
                      protect your Personal Information, we cannot guarantee its
                      absolute security.
                    </p>

                    <h2>Advertisements</h2>
                    <p>
                      Ads appearing on our website may be delivered to users by
                      advertising partners, who may set cookies. These cookies
                      allow the ad server to recognize your computer each time
                      they send you an online advertisement to compile
                      information about you or others who use your computer.
                      This information allows ad networks to, among other
                      things, deliver targeted advertisements that they believe
                      will be of most interest to you. This Privacy Policy
                      covers the use of cookies by www.company.kitchen and does
                      not cover the use of cookies by any advertisers.
                    </p>

                    <h2>Links To External Sites</h2>
                    <p>
                      Our Service may contain links to external sites that are
                      not operated by us. If you click on a third party link,
                      you will be directed to that third party's site. We
                      strongly advise you to review the Privacy Policy and terms
                      and conditions of every site you visit. We have no control
                      over, and assume no responsibility for the content,
                      privacy policies or practices of any third party sites,
                      products or services.
                    </p>

                    <h2>
                      www.company.kitchen uses Google AdWords for remarketing
                    </h2>
                    <p>
                      www.company.kitchen uses the remarketing services to
                      advertise on third party websites (including Google) to
                      previous visitors to our site. It could mean that we
                      advertise to previous visitors who haven't completed a
                      task on our site, for example using the contact form to
                      make an enquiry. This could be in the form of an
                      advertisement on the Google search results page, or a site
                      in the Google Display Network. Third-party vendors,
                      including Google, use cookies to serve ads based on
                      someone's past visits. Of course, any data collected will
                      be used in accordance with our own privacy policy and
                      Google's privacy policy. You can set preferences for how
                      Google advertises to you using the Google Ad Preferences
                      page, and if you want to you can opt out of interest-based
                      advertising entirely by cookie settings or permanently
                      using a browser plugin.
                    </p>

                    <h2>
                      Protection of Certain Personally-Identifying Information
                    </h2>
                    <p>
                      www.company.kitchen discloses potentially
                      personally-identifying and personally-identifying
                      information only to those of its employees, contractors
                      and affiliated organizations that (i) need to know that
                      information in order to process it on
                      www.company.kitchen's behalf or to provide services
                      available at www.company.kitchen's website, and (ii) that
                      have agreed not to disclose it to others. Some of those
                      employees, contractors and affiliated organizations may be
                      located outside of your home country; by using
                      www.company.kitchen's website, you consent to the transfer
                      of such information to them. www.company.kitchen will not
                      rent or sell potentially personally-identifying and
                      personally-identifying information to anyone. Other than
                      to its employees, contractors and affiliated
                      organizations, as described above, www.company.kitchen
                      discloses potentially personally-identifying and
                      personally-identifying information only in response to a
                      subpoena, court order or other governmental request, or
                      when www.company.kitchen believes in good faith that
                      disclosure is reasonably necessary to protect the property
                      or rights of www.company.kitchen, third parties or the
                      public at large. If you are a registered user of
                      <a href="www.company.kitchen">
                        http://www.company.kitchen
                      </a>{' '}
                      and have supplied your email address, www.company.kitchen
                      may occasionally send you an email to tell you about new
                      features, solicit your feedback, or just keep you up to
                      date with what's going on with www.company.kitchen and our
                      products. We primarily use our blog to communicate this
                      type of information, so we expect to keep this type of
                      email to a minimum. If you send us a request (for example
                      via a support email or via one of our feedback
                      mechanisms), we reserve the right to publish it in order
                      to help us clarify or respond to your request or to help
                      us support other users. www.company.kitchen takes all
                      measures reasonably necessary to protect against the
                      unauthorized access, use, alteration or destruction of
                      potentially personally-identifying and
                      personally-identifying information.
                    </p>

                    <h2>Aggregated Statistics</h2>
                    <p>
                      www.company.kitchen may collect statistics about the
                      behavior of visitors to its website. www.company.kitchen
                      may display this information publicly or provide it to
                      others. However, www.company.kitchen does not disclose
                      your personally-identifying information.
                    </p>

                    <h2>Cookies</h2>
                    <p>
                      To enrich and perfect your online experience,
                      www.company.kitchen uses "Cookies", similar technologies
                      and services provided by others to display personalized
                      content, appropriate advertising and store your
                      preferences on your computer. A cookie is a string of
                      information that a website stores on a visitor's computer,
                      and that the visitor's browser provides to the website
                      each time the visitor returns. www.company.kitchen uses
                      cookies to help www.company.kitchen identify and track
                      visitors, their usage of http://www.company.kitchen, and
                      their website access preferences. www.company.kitchen
                      visitors who do not wish to have cookies placed on their
                      computers should set their browsers to refuse cookies
                      before using www.company.kitchen's websites, with the
                      drawback that certain features of www.company.kitchen's
                      websites may not function properly without the aid of
                      cookies.
                    </p>
                    <p>
                      By continuing to navigate our website without changing
                      your cookie settings, you hereby acknowledge and agree to
                      www.company.kitchen's use of cookies.
                    </p>

                    <h2>Privacy Policy Changes</h2>
                    <p>
                      Although most changes are likely to be minor,
                      www.company.kitchen may change its Privacy Policy from
                      time to time, and in www.company.kitchen's sole
                      discretion. www.company.kitchen encourages visitors to
                      frequently check this page for any changes to its Privacy
                      Policy. Your continued use of this site after any change
                      in this Privacy Policy will constitute your acceptance of
                      such change.
                    </p>

                    <h2>Credit & Contact Informationot</h2>
                    <p>
                      This privacy policy was created at
                      <a
                        href="https://termsandconditionstemplate.com/privacy-policy-generator/"
                        traget="_blank"
                      >
                        https://termsandconditionstemplate.com/privacy-policy-generator/
                      </a>{' '}
                      . If you have any questions about this Privacy Policy,
                      please contact us via or phone.
                    </p>
                    <Link to="/"> {'<-'} Back</Link>
                  </div>
                </div>
              </div>
            </div>
          </Route>
          <Route path="/">
            <div
              className={classNames('home', {
                active: true,
              })}
            >
              <div id="containerVideo">
                <div
                  id="largeVideo"
                  className={classNames({
                    h1250:
                      windowSize.height < 1250 &&
                      windowSize.height >= 1150 &&
                      ratio > 1.2,
                    h1150:
                      windowSize.height < 1150 &&
                      windowSize.height >= 1050 &&
                      ratio > 1.2,
                    h1050:
                      windowSize.height < 1050 &&
                      windowSize.height >= 975 &&
                      ratio > 1.2,
                    h975:
                      windowSize.height < 975 &&
                      windowSize.height >= 750 &&
                      ratio > 1.2,
                    h750:
                      windowSize.height < 750 &&
                      windowSize.height >= 550 &&
                      ratio > 1.2,
                    h550:
                      windowSize.height < 550 &&
                      windowSize.height >= 450 &&
                      ratio > 1.2,
                    h450:
                      windowSize.height < 450 &&
                      windowSize.height >= 300 &&
                      ratio > 1.2,
                    h350: windowSize.height < 300 && ratio > 1.2,
                    'pre-landscape':
                      windowSize.height < 780 &&
                      windowSize.width <= 768 &&
                      windowSize.width > 500,
                    landscape:
                      windowSize.height < 780 && windowSize.width <= 768,
                    top10:
                      windowSize.height <= 750 &&
                      windowSize.height > 700 &&
                      windowSize.width <= 768,
                    top5:
                      windowSize.height <= 700 &&
                      windowSize.height > 500 &&
                      windowSize.width <= 768,
                    top0: windowSize.height <= 500 && windowSize.width <= 768,
                  })}
                >
                  {isMobileSafari && isSafari ? (
                    <ReactPlayer
                      className="react-player"
                      url={Video_Teaser}
                      playing={false}
                      loop={false}
                      controls={playerControls}
                      width={width}
                      height={height}
                      onEnded={() => {
                        setPlayerControls(false);
                      }}
                      onMouseEnter={() => setPlayerControls(true)}
                      onMouseLeave={() => setPlayerControls(false)}
                    />
                  ) : (
                    <video
                      className="react-player"
                      controls={playerControls}
                      onMouseEnter={() => setPlayerControls(true)}
                      onMouseLeave={() => setPlayerControls(false)}
                      width={width}
                      height={height}
                      playsInline={true}
                    >
                      <source src={Video_Teaser} type="video/mp4" />
                      Sorry, your browser doesn't support embedded videos.
                    </video>
                  )}
                  <div
                    className={classNames('bottom', {
                      landscape: true,
                    })}
                  >
                    <a href="mailto:hungry@company.kitchen">
                      hungry@company.kitchen
                    </a>
                  </div>
                </div>
                <div className="clearboth"></div>
              </div>

              <div
                className={classNames('prefooter', {
                  landscape: true,
                })}
              >
                <Link to="/impressum">Impressum - legal notice</Link>
                <Link to="/privacy-policy">Privacy policy</Link>
              </div>
              <div
                className={classNames('footer', {
                  landscape: true,
                })}
              >
                <span>
                  © 2021 COMPANY<strong>KITCHEN</strong>. All rights reserved.
                </span>
              </div>
            </div>
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
